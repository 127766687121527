



















































import { Component, Vue } from 'vue-property-decorator'
import { getUseCoinLevelInfo } from '@/pages/videoGame/api'
@Component
export default class extends Vue {
  taskList: any = []

  async getData() {
    //
    const { data } = await getUseCoinLevelInfo({ activityType: 1 })
    this.taskList = data.levels
  }
  mounted() {
    this.getData()
  }
}
